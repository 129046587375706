import * as React from "react"
import Layout from "../components/layout"
import { Router, Link } from '@reach/router'
import ClassInternal from "../components/class-internal"


const App = () => {
    return (
      <Layout>
        <Router basepath="/class">
          <ClassInternal path="/:id" />
          <ClassInternal path="/" />
        </Router>
      </Layout>
    )
  }
  export default App